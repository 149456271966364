import React from 'react'
import Layout from '../components/layout'
import '../styles/AboutUs.css'
import {Helmet} from 'react-helmet'

import FloatingWhatsApp from 'react-floating-whatsapp'
import logoRamsko from '../images/logo4.jpg'
import favicon from '../images/favicon.ico'


const AboutUs = () => {
  return (
    <div className='bg-white'>
      <Layout>
      <Helmet title="About Us | Ramasko | راماسكو"
          meta={[
                    {
                        name:'charSet',
                        content:'utf-8',
                    },
                    {
                        name:'description',
                        content:'Ramasko',
                    }
                ]}
        />
        <Helmet>
          <link rel="icon" href={favicon} />
        </Helmet>

          <div className='AboutUs flex justify-center xl:max-w-6xl 2xl:max-w-7xl lg:max-w-5xl md:max-w-3xl mx-auto px-4 sm:px-6'>
              <div className='roundRectangleAbout bg-white text-black flex justify-center items-center'>
                <div className='' dir='rtl'>
                  <h1 className='titleAbout text-center font-Almarai'>معلومات عنا</h1>
                  <h1 className='subTitleAbout font-Almarai'>
                  نحن مؤسسة راماسكو لانظمة تقنيةالتغليف . متخصصون في استيراد وبيع المكائن الصناعيه والتغليف واكياس النايلو ال بي في سي  , واكياس التغليف  بي يو اف  واكياس تغليف العطور او بي بي  كما نقوم بتفصيل الاكياس علي حسب المقاسات وحسب الطلب .. يوجد لدينا مكائن الشرنك الحراري بجميع مقاساته ومكائن الاندكشن (تلحيم القصدير علي البرطمانات والعلب البلاستيكيه)ولدينا مكائن القص الحراري بجميع مقاساته ومكائن تعبية السوائل والعطور والكريمات والمواد اللزجه ولدينا قطع الغيار والصيانه .
                  </h1>
                </div>
              </div>
          </div>
          <FloatingWhatsApp
          phoneNumber = "+966551161149"
          accountName = "راماسكو"
          statusMessage = "كتابة ..."
          chatMessage = "كيف يمكننا مساعدتك؟"
          avatar = {logoRamsko}
          allowClickAway
          allowEsc
          notificationSound = "true"
         />
      </Layout>
    </div>
  )
}

export default AboutUs
